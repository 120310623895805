<template>

  <div>
    <b-modal
      v-if="checkingId"
      centered
      :ok-variant="mapOkVariant(checkingApplication.status)"
      cancel-variant="outline-danger"
      :ok-only="checkingApplication.status == 'approved'"
      :ok-title="mapOkTitle(checkingApplication.status)"
      cancel-title="驳回"
      id="modal-application-detail"
      :title="checkingApplication.company_name"
      @ok="approveApplication(checkingId)"
      @cancel="rejectApplication(checkingId)"
    >
      <b-card-text class="d-flex justify-content-center">公司信息</b-card-text>
      <b-card-text>公司名称：{{ checkingApplication.company_name }}</b-card-text>
      <b-card-text>注册地址：{{ checkingApplication.company_address }}</b-card-text>
      <b-card-text>税号：{{ checkingApplication.company_tax_number }}</b-card-text>
      <b-card-text>开户银行：{{ checkingApplication.company_opening_bank }}</b-card-text>
      <b-card-text>银行账号：{{ checkingApplication.company_bank_account }}</b-card-text>
      <b-card-text>税号：{{ checkingApplication.company_tax_number }}</b-card-text>
      <b-card-text>座机：{{ checkingApplication.company_telephone }}</b-card-text>
      <b-card-text>全职标注员数量：{{ checkingApplication.labelers }}</b-card-text>

      <b-card-text>能力标签：
        <b-badge class="mr-1" variant="light-primary" v-for="a in checkingApplication.abilities">{{ mapAbility(a) }}</b-badge>
      </b-card-text>

      <b-card-text>介绍文件：
        <template v-if="checkingApplication.intro">
        <a v-if="checkingApplication.intro.intro_file_fs_id"
              class="text-primary"
              @click="downloadIntro(checkingApplication.intro.intro_file_fs_id, checkingApplication.intro.intro_file_name)"
              :disabled="fileDownloading">
              <font-awesome-icon
                icon="fa-solid fa-paperclip"
              />
              {{ checkingApplication.intro.intro_file_name }}
            </a>
          </template>
      </b-card-text>

      <b-card-text>营业执照：
        <template v-if="checkingApplication.license">
        <a v-if="checkingApplication.license.license_file_fs_id"
              class="text-primary"
              @click="downloadIntro(checkingApplication.license.license_file_fs_id, checkingApplication.license.license_file_name)"
              :disabled="licenseDownloading">
              <font-awesome-icon
                icon="fa-solid fa-paperclip"
              />
              {{ checkingApplication.license.license_file_name }}
            </a>
          </template>
      </b-card-text>

      <hr><b-card-text class="d-flex justify-content-center">联系人信息</b-card-text>
      <b-card-text>姓：{{ checkingApplication.contact_last_name }}</b-card-text>
      <b-card-text>名：{{ checkingApplication.contact_first_name }}</b-card-text>
      <b-card-text>性别：{{ mapGender(checkingApplication.contact_gender) }}</b-card-text>
      <b-card-text>职位：{{ checkingApplication.contact_title }}</b-card-text>
      <b-card-text>电话：{{ checkingApplication.contact_phone }}</b-card-text>
      <b-card-text>邮箱：{{ checkingApplication.contact_email }}</b-card-text>
    </b-modal>

    <contact-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :company-options="companyOptions"
      @get-contacts="getContacts"
    />

    <contact-edit
      :is-edit-user-sidebar-active.sync="isEditUserSidebarActive"
      :contact-data="contactData"
      :company-options="companyOptions"
      @get-contacts="getContacts"
    />

<!--     <contact-filters
      :user-filter.sync="userFilter"
      :user-options="userOptions"
      :company-filter.sync="companyFilter"
      :company-options="companyOptions"
    /> -->

    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <b-row>

          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>展示</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>条数据</label>
          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="搜索"
                v-b-tooltip.hover.bottom="`Email`"

              />
<!--               <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true;;"
              >
                <span class="text-nowrap">添加联系人</span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative text-nowrap"
        :items="filterContacts"
        :per-page="perPage"
        :current-page="currentPage"
        responsive
        :fields="fields"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <template #cell(abilities)="data">
          <b-badge 
            v-for="i in data.item.abilities" 
            variant="light-primary"
            class="mr-1">
            {{mapAbility(i)}}
          </b-badge>
        </template>

        <template #cell(status)="data">
          <b-badge
            :variant="mapStatusVariant(data.item.status)"
            class="mr-1">
            {{mapStatus(data.item.status)}}
          </b-badge>
        </template>

        <template #cell(intro_file_name)="data">
          <template v-if="data.item.intro">
          <a v-if="data.item.intro.intro_file_fs_id"
              class="text-primary"
              @click="downloadIntro(data.item.intro.intro_file_fs_id, data.item.intro.intro_file_name)"
              :disabled="fileDownloading">
              <font-awesome-icon
                icon="fa-solid fa-paperclip"
              />
              {{ data.item.intro.intro_file_name }}
            </a>
            </template>
        </template>

        <template #cell(license_file_name)="data">
          <template v-if="data.item.license">
          <a v-if="data.item.license.license_file_fs_id"
              class="text-primary"
              @click="downloadLicense(data.item.license.license_file_fs_id, data.item.license.license_file_name)"
              :disabled="licenseDownloading">
              <font-awesome-icon
                icon="fa-solid fa-paperclip"
              />
              {{ data.item.license.license_file_name }}
            </a>
          </template>
        </template>

        <template #cell(actions)="data">



          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item  v-b-modal.modal-application-detail @click="setCheckingId(data.item.id) ">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">查看详情</span>
            </b-dropdown-item>

<!--             <b-dropdown-item @click="invite(data.item.id)">
              <feather-icon icon="MailIcon" />
              <span class="align-middle ml-50">发送邀约邮件</span>
            </b-dropdown-item>

            <b-dropdown-item @click="approve(data.item.id)" v-if="data.item.status == 'pending'">
              <feather-icon icon="CheckCircleIcon" />
              <span class="align-middle ml-50">通过</span>
            </b-dropdown-item>

            <b-dropdown-item @click="reject(data.item.id)" v-if="data.item.status == 'pending'">
              <feather-icon icon="XCircleIcon" />
              <span class="align-middle ml-50">驳回</span>
            </b-dropdown-item> -->

          
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">正在展示第 {{ dataMeta.from }} 到第 {{ dataMeta.to }} 条数据 （共 {{ totalContacts }} 条数据）</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalContacts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,VBTooltip,BModal,VBModal,BCardText,BCardTitle
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ContactFilters from './ContactFilters.vue'
import ContactAddNew from './ContactAddNew.vue'
import ContactEdit from './ContactEdit.vue'

export default {
  components: {
    BModal,
    ContactFilters,
    ContactAddNew,
    ContactEdit,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardText,
    vSelect,
    BCardTitle
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,

  },
  data(){
    return {
      checkingId:'',
      licenseDownloading:false,
      fileDownloading:false,
      isSortDirDesc: false,
      perPageOptions: [10, 25, 50, 100],
      isAddNewUserSidebarActive: false,
      isEditUserSidebarActive: false,
      selectedContact: "",
      perPage:10,
      currentPage:1,
      contacts: [],
      companies: [],
      searchQuery: "",
      userOptions: [],
      companyOptions: [],
      userFilter: "",
      companyFilter:"",
      fields:[
        {key:"user_email", label:"Email"},
        {key:"company_name", label:"公司名称"},
        {key:"labelers", label:"全职标注员数量"},
        {key:"abilities", label:"能力标签"},
        {key:"intro_file_name", label:"介绍文件"},
        {key:"license_file_name", label:"营业执照"},
        {key:"time_cn", label:"注册时间"},
        {key:"status", label:"入库状态"},
        {key:"actions", label:"操作"},
      ],
    }
  },
  computed:{
    checkingApplication(){
      return this.contacts.filter(item => item.id == this.checkingId)[0]
    },
    dataMeta: function(){
      return {
        from: (this.currentPage - 1) * this.perPage + 1,
        to: Math.min(this.currentPage * this.perPage, this.totalContacts),
      }
    },
    contactData: function(){
      return this.contacts.filter(item => item.contact_id == this.selectedContact)[0]
    },
    totalContacts: function(){
      return this.filterContacts.length
    },
    filterContacts: {
      get(){
        if(this.searchQuery == "") return this.contacts
        return this.contacts.filter((contact)=>{
            return contact.user_email.includes(this.searchQuery)
        })
      },
    },
  },
  methods:{
    setCheckingId(id){
      this.checkingId = id;
    },
    approveApplication(id){
      if (this.contacts.find(item => item.id == id).status == 'approved') return;

      this.$axios.get('/vendors/applications/approve/' + id).then((res)=>{
        if (res.data.status == "ok"){
          this.getContacts();
        }
      })
    },
    rejectApplication(id){
      this.$axios.get('/vendors/applications/reject/' + id).then((res)=>{
        if (res.data.status == "ok"){
          this.getContacts();
        }
      })
    },
    downloadLicense(fileId,fileName){
      this.licenseDownloading = true;
      this.$axios.get('/vendors/applications/download_license/' + fileId).then((res)=>{
        if (res.data.status == "ok"){
          this.licenseDownloading = false;
          const url = this.$backend + '/download_by_token/' + res.data.data.token
          const link = document.createElement('a')
          link.setAttribute('href', url)
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          link.remove()
        }
      })
    },
    getUsers(){
      this.$axios.get("/manage/users/get_users").then((res)=>{
        if (res.data.status == "ok"){
          res.data.data.users.forEach((user)=>{
            this.userOptions.push({label:user.user_name,value:user.user_id})
          })
        }
      })
    },
    getContacts(){
      this.$axios.get("vendors/applications/get_applications").then((res)=>{
        if (res.data.status == "ok"){
          this.contacts = res.data.data.applications;
        }
      })
    },
    getCompanies: function(){
      this.$axios.get("vendors/companies/get_companies").then((res)=>{
        if (res.data.status == "ok"){

          res.data.data.companies.forEach((company)=>{
            this.companyOptions.push({label:company.name,value:company.company_id})
          })
        }
      })
    },
    deleteContact: function(contact_id){
      this.$swal({
        title: '删除联系人',
        text: "删除后无法撤销，你确定要删除该联系人吗？",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '是的，删除！',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$axios.get("vendors/contacts/del_contact/" + contact_id).then((res)=>{
        if (res.data.status == "ok"){
          this.getContacts();
        }
      })        
    }
      })
    },
    downloadIntro(id,fileName){
      this.fileDownloading = true
      this.$axios.get("/vendors/applications/download_intro/" + id).then(res=>{  
        this.fileDownloading = false
        if (res.data.status == "ok"){
          const url = this.$backend + '/download_by_token/' + res.data.data.token
          const link = document.createElement('a')
          link.setAttribute('href', url)
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          link.remove()
        }      
      })
    },
    mapAbility(a){
      if(a == 'image'){
        return '图像标注'
      }else if(a == 'text'){
        return '文本标注'
      }else if(a == 'pointcloud'){
        return '点云标注'
      }else if(a == 'audio'){
        return '音频标注'
      }else if(a == 'collection'){
        return '数据采集'
      }
    },
    mapStatus(status){
      if(!status) {
        return '未入库'
      }else if(status == 'approved'){
        return '已入库'
      }else if(status == "rejected"){
        return '已驳回'
      }else{
        return '未入库'
      }
    },
    mapStatusVariant(status){
      if(!status) {return 'light-secondary'
      }else if(status == 'approved'){
        return 'light-success'
      }else if(status == "pending"){
        return 'light-warning'
      }else if(status == 'rejected'){
        return 'light-danger'
      }else{
        return 'light-secondary'
      }
    },
    mapGender(gender){
      if(gender == 'male'){
        return '男'
      }else if(gender == 'female'){
        return '女'
      }
    },
    mapOkTitle(status){
      if(status == 'approved'){
        return '关闭'
      }else{
        return '通过'
      }
    },
    mapOkVariant(status){
      if(status == 'approved'){
        return 'primary'
      }else{
        return 'success'
      }
    }
  },
  created(){
    this.getContacts() //getApplications 懒得改名了
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
